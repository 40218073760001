<template>
  <div>
    <ActionList
      :preloaded-items-list="items"
      :empty-text="$t('noItemsFound')"
      :load-items-callback="null"
    >
      <template v-slot:item="{ item, index }">
        <v-list-item @click="showItemProfile(item)">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.price.amount }} {{ item.price.currency_code }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="index"
          inset
        />
      </template>
    </ActionList>
  </div>
</template>

<script>
import ActionList from '@/components/common/list/ActionList'
export default {
	name: 'ItemListSimple',
	components: {
		ActionList
	},
	props: {
		items: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		showItemProfile(item) {
			this.$store.commit('updateContextOverlay', {
				'component': 'components/item/ItemProfile',
				'props': {
					'item': item
				}
			})
		},
	}
}
</script>

<style scoped>

</style>
