import { render, staticRenderFns } from "./ItemListSimple.vue?vue&type=template&id=a68a7370&scoped=true"
import script from "./ItemListSimple.vue?vue&type=script&lang=js"
export * from "./ItemListSimple.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a68a7370",
  null
  
)

export default component.exports