<template>
  <div v-if="category">
    <v-dialog
      v-if="editCategory"
      v-model="editCategory"
      @click:outside="editCategory = false"
      max-width="800"
    >
      <v-card>
        <v-card-title>
          {{ $t('edit') + ' ' + category.name }}
        </v-card-title>
        <v-card-text>
          <CategoryForm
            v-model="category"
            edit
            @updated="categoryWasUpdated"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card outlined>
      <v-toolbar flat>
        <v-toolbar-title v-if="category.name">
          {{ category.name }}
        </v-toolbar-title>
        <v-toolbar-title
          v-else
          class="red--text font-italic pr-1"
        >
          {{ $t('nameRequired') }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          depressed
          color="primary"
          @click="editCategory = true"
        >
          {{ $t('edit') }}
        </v-btn>
      </v-toolbar>
      <v-card-subtitle class="py-0">
        {{ $t('numberOfItemsInCategoryItemsLength', {itemsLength: ItemsInCategory.length}) }}
      </v-card-subtitle>
      <v-card-subtitle>
        <v-chip
          v-if="userIsAdmin && false"
          x-small
          outlined
          class="mr-3"
        >
          {{ $t('adminOnly') }}
        </v-chip>
        <v-chip
          x-small
          outlined
          class="mr-3"
        >
          {{ $t('uuid') }}
        </v-chip>
        <span>{{ category.uuid }}</span>
        <span v-if="userIsAdmin">
          <v-chip
            x-small
            outlined
            class="text-uppercase ml-5 mr-2"
          >
            {{ $t('Legacy Id') }}
          </v-chip>
          <span>
            {{ category.legacyId }}
          </span>
        </span>
      </v-card-subtitle>
      <v-tabs v-model="selectedTab">
        <template v-for="tab in tabs">
          <v-tab
            :key="tab.key"
            :href="'#' + tab.key"
          >
            {{ tab.label }}
          </v-tab>
        </template>
      </v-tabs>
    </v-card>
    <v-tabs-items
      class="mt-3 transparent"
      v-model="selectedTab"
    >
      <template v-for="tab in tabs">
        <v-tab-item
          class="transparent"
          :key="tab.key"
          :value="tab.key"
        >
          <v-card
            v-if="selectedTab === 'stats'"
            class="transparent"
          >
            <v-row dense>
              <v-col cols="12">
                <FilterHeader
                  use-date
                  @newValues="updateFilters($event)"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <TransientDash
                  :key="category.name + ':sales:' + startTimeInMillis + ':' + endTimeInMillis"
                  :title="$t('sales')"
                  namespace="item_aggregate_value"
                  :config="itemAggregateValueConfig"
                  :start-time-in-millis="startTimeInMillis"
                  :end-time-in-millis="endTimeInMillis"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <TransientDash
                  :key="category.name + ':cost:' + startTimeInMillis + ':' + endTimeInMillis"
                  :title="$t('cost')"
                  namespace="item_cost_aggregate_value"
                  :config="itemAggregateValueConfig"
                  :start-time-in-millis="startTimeInMillis"
                  :end-time-in-millis="endTimeInMillis"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <TransientDash
                  :key="category.name + ':profit:' + startTimeInMillis + ':' + endTimeInMillis"
                  :title="$t('profit')"
                  namespace="item_profit_aggregate_value"
                  :config="itemAggregateValueConfig"
                  :start-time-in-millis="startTimeInMillis"
                  :end-time-in-millis="endTimeInMillis"
                />
              </v-col>
              <v-col cols="12">
                <TransientDash
                  :key="category.name + ':dates:' + startTimeInMillis + ':' + endTimeInMillis"
                  :title="$t('salesByDates')"
                  namespace="item_aggregate_value_by_date"
                  :config="itemAggregateValueConfig"
                  :start-time-in-millis="startTimeInMillis"
                  :end-time-in-millis="endTimeInMillis"
                />
              </v-col>
              <v-col cols="12">
                <TransientDash
                  :key="category.name + ':category:' + startTimeInMillis + ':' + endTimeInMillis"
                  :title="$t('salesByCategory')"
                  namespace="item_summary_list"
                  :config="itemAggregateValueConfig"
                  :start-time-in-millis="startTimeInMillis"
                  :end-time-in-millis="endTimeInMillis"
                />
              </v-col>
            </v-row>
          </v-card>
          <v-card
            outlined
            v-if="selectedTab === 'items'"
            class="transparent"
          >
            <ItemListSimple :items="ItemsInCategory" />
          </v-card>
        </v-tab-item>
      </template>
    </v-tabs-items>
  </div>
</template>

<script>

import TransientDash from '@/components/dash/TransientDash.vue'
import CategoryForm from "@/components/CategoryForm"
import FilterHeader from '@/components/universalFilter/FilterHeader.vue'
import ItemListSimple from '@/components/item/ItemListSimple'

export default {
    name: 'CategoryProfile',
    props: ['category', 'uuid'],
    components: {
      CategoryForm,
      TransientDash,
      FilterHeader,
			ItemListSimple
    },
    data() {
      return {
        selectedTab: 'stats',
        oneDayInMillis: 86400000,
        editCategory: false
      }
    },
    methods: {
      updateFilters(filters) {
        this.startTimeInMillis = filters.startTimeInMillis
        this.endTimeInMillis = filters.endTimeInMillis
      },
      getCategory() {
        this.category = this.$store.state.categories.find(category => category.uuid === this.uuid)
      },
      categoryWasUpdated(category) {
        if(category) {
          this.editCategory = false
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('categoryCategoryNameWasUpdated', {categoryName: category.name})
          })
        }
      },
    },
    computed: {
      startTimeInMillis: {
        get() {
          return this.$store.state.profileStatsStartTimeInMillis
        },
        set(value) {
          this.$store.commit('updateProfileStatsStartTimeInMillis', value)
        }
      },
      endTimeInMillis: {
        get() {
          return this.$store.state.profileStatsEndTimeInMillis
        },
        set(value) {
          this.$store.commit('updateProfileStatsEndTimeInMillis', value)
        }
      },
      tabs() {
        const tabs = []

        tabs.push({
          key: 'stats',
          label: this.$t('stats')
        },
				{
					key: 'items',
					label: this.$t('itemsInCategory')
				})

        return tabs
      },
      nowInMillis() {
        return new Date().getTime()
      },
      last24HoursStartTimeInMillis() {
        return new Date().getTime() - this.oneDayInMillis
      },
      last7DaysStartTimeInMillis() {
        return new Date().getTime() - (this.oneDayInMillis * 7)
      },
      last30DaysStartTimeInMillis() {
        return new Date().getTime() - (this.oneDayInMillis * 30)
      },
      itemAggregateValueConfig() {

        if(this.category === null || this.category === undefined) {
          return {}
        }

        return {
          include_categories: [this.category.uuid]
        }
      },
      userIsAdmin() {
        return this.$store.getters.userIsAdmin
      },
			items() {
      	return this.$store.state.items
			},
			ItemsInCategory() {
				const ItemList = []

				if (this.items === undefined || this.items === null) {
					return ItemList
				}

				for (let i = 0; i < this.items.length; i++) {
					const item = this.items[i]
					const categories = item.categories

					if( categories === null || typeof categories === 'undefined') {
						continue
					}

					if (!Array.isArray(categories)) {
						continue
					}

					for (let j = 0; j < categories.length; j++) {
						const category = categories[j]
						if (category !== null && typeof category !== 'undefined' && this.category !== null && typeof this.category !== 'undefined' &&  typeof category.uuid !== 'undefined' && category.uuid !== null && category.uuid === this.category.uuid) {
							ItemList.push(item)
						}
					}
				}

				return ItemList
			},
    },
    mounted() {
      if(this.category === undefined && this.uuid !== null) {
        this.getCategory()
      }
    }
}
</script>
